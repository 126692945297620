export default class InputValidators {

    constructor() {
    }


    validateField(self,e, validationType) {
        let element = $(e.currentTarget);
        switch (validationType) {
            case "required":
                self.validateRequiredFields(self, element);
                break;
            case "email":
                self.validateEmailFields(self, element);
                break;
        }
    }


    validateRequiredFields(self,element) {
        let input = element;
        if (!$(input).val()) {
            $(input).siblings('span').remove();
            $(input).parent().append('<span><small class="validation-text required-message">' + $('#requiredMessage').val() + '</small></span>');
            if (!$(input).hasClass('is-invalid')) { 
                $(input).addClass('is-invalid').removeClass('is-valid');
            }
        } else {
            if ($(input).siblings('span').find('.required-message').length) {
                $(input).removeClass('is-invalid').addClass('is-valid');
                $(input).siblings('span').remove();
            }
        }
    }

    validateEmailFields(self, element) {
        let input = element;
        let regularExpresion = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        let isEmail = regularExpresion.test($(element).val());
        if (!isEmail) {
            $(input).siblings('span').remove();
            $(input).parent().append('<span><small class="validation-text bad-email-message">' + $('#badEmailMessage').val() + '</small></span>');
            if (!$(input).hasClass('is-invalid')) {
                $(input).addClass('is-invalid').removeClass('is-valid');
            }

        } else {
            if ($(input).siblings('span').find('.bad-email-message').length > 0) {
                $(input).addClass('is-valid').removeClass('is-invalid');
                $(input).siblings('span').remove();
            }

        }
    }

    validatePasswordFields(self, element) {

    }

    validateNumberFields(self, element) {

    }

    isValidForm(form) {
        let isValidForm = true;
        let formData = $(form).serializeArray();
        for (let tempData in formData) {
            let element = $(form).find('input[name="' + formData[tempData]['name'] + '"]');
            let isInvalid = $(element).hasClass('is-invalid') || (!$(element).val() && $(element).prop('required'));
            if (isInvalid) {
                isValidForm = false;
                break;
            }
        }

        return isValidForm;
    }


}