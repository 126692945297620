import InputValidators from '../Main/input-validators';

class Authentication {

    constructor() {
        this.init();
    }

    init() {
        this.addAuthenticationEvents();
        this.inputValidator = new InputValidators();
        this.baseUrl = '/ecommerce/cart/';
    }

    addAuthenticationEvents() {
        const self = this;
        this.onDocumentLoad(self);
        this.onInputEventsToValidation(self);
        this.onAuthenticateClick(self);
        this.onRegisterButtonClick(self);
        this.onLostPasswordClick(self);
        this.onNifChange(self);
        this.onAuthenticationFormInputsKeyPress(self);
        this.onOpenModalRegister(self);
        this.onCloseModalRegister(self);
        this.onShowAuthenticatioModal(self);
        this.onShownAuthenticatioModal(self);
        /*
        this.onOpenModalLogin(self);
        this.onCloseModaLogin(self);

        this.onOpenModalLoginRecovery(self);
        this.onCloseModalLoginRecovery(self);
        */

    }
    
    onDocumentLoad(self) {
        $(window).on('load', function (e) {
            let storageRegisteredUserType = window.localStorage.getItem("isRegistered");
            let storageAuthenticatedUser = window.localStorage.getItem("isAuthenticated");
            let isEnabledShopping = $('#isEnabledShopping').val()?.toLowerCase() === 'true';
            let isCartPage = $('#isCartPage').val()?.toLowerCase() === 'true';
            let showMustValidationMessageByExternalAuth = $('#showMustValidationMessageByExternalAuth').val()?.toLowerCase() === 'true';

            let isRegistredEcommerceUser = storageRegisteredUserType === 'ecommerce';
            let isRegistredCommonUser = storageRegisteredUserType === 'common';
            let isAuthenticated = storageAuthenticatedUser === 'true';

            if (isAuthenticated) {
                if (isCartPage) {
                    $('#modalSetAddress').modal('show');
                }
            }

            if (isRegistredEcommerceUser) {
                if (isEnabledShopping) {
                    let personalDataFromLocalStorage = JSON.parse(window.localStorage.getItem('personalData'));
                    if (personalDataFromLocalStorage) {
                        self.verifyAndGetRegistredUserId(self, personalDataFromLocalStorage.UserName).then(function (result) {
                            if (result.isRegistredUser) {
                                let userId = result.userId;
                                personalDataFromLocalStorage.UserId = userId;
                                self.registerPersonalData(self, personalDataFromLocalStorage).then(function (result) {
                                    if (!result.isSavedUserData) {
                                        console.log("Error saving user data!");
                                    }
                                    window.localStorage.removeItem("personalData");
                                });

                                self.setEcommerceUserRole(self, personalDataFromLocalStorage.UserName);
                            }

                        });
                    }
                }

            }

            let isAuthenticatedByExternalPlataform = self.getUrlParameterValue("ExternalAuthentication") === "true";

            if (isRegistredEcommerceUser || isRegistredCommonUser || isAuthenticatedByExternalPlataform) {
                if ($('#usersMustValidation').val().trim().toLowerCase() === 'false') {
                    $('body').append('<div class="alert alert-dismissable alert-success fade in alert-position-fixed alert-success-register" style="bottom: 50px;">' + $('#registrationMessage').val() + '<button type="button" class="close" data-dismiss="alert"><span aria-hidden="true">×</span></button></div>');
                    if (isCartPage) {
                        $('#modalSetAddress').modal('show');
                    }
                } else {
                    $('body').append('<div class="alert alert-dismissable alert-warning fade in alert-position-fixed alert-warning-register" style="bottom: 50px;">' + $('#registrationMessage').val() + '<button type="button" class="close" data-dismiss="alert"><span aria-hidden="true">×</span></button></div>');
                }
            }

            window.localStorage.removeItem("isRegistered");
            window.localStorage.removeItem("personalData");
            window.localStorage.removeItem("isAuthenticated");

            history.replaceState(
                null, '', location.pathname + location.search.replace(/[\?&]ExternalAuthentication=[^&]+/, '').replace(/^&/, '?')
            );
        });
    }

    onInputEventsToValidation(self) {

        $('#registerForm').find('input[required]').on('blur', function (e) {
            self.inputValidator.validateField(self.inputValidator, e, 'required');
        });

        $('#registerForm').find('input[type="email"]').on('keyup', function (e) {
            self.inputValidator.validateField(self.inputValidator, e, 'email');
        });

        $('#lostPasswordForm').find('input[required]').on('blur', function (e) {
            self.inputValidator.validateField(self.inputValidator, e, 'required');
        });

    }


    onAuthenticateClick(self) {
        $(document).on('click', '#authenticate', function (e) {
            self.logOn(self);
        });

        /*
        $(document).on('keydown', 'input', function (e) {
            if (e.keyCode == 13) {
                self.logOn(self);
            }
        });
        */
    }

    onRegisterButtonClick(self) {
        $(document).on('click', '#registerButton', function (e) {
            self.register(self);
        });

        /*
        $(document).on('keydown', 'input', function (e) {
            if (e.keyCode == 13) {
                self.register(self);
            }
        });
        */
    }

    onLostPasswordClick(self) {
        $(document).on('click', '#recoverPassword', function (e) {
            self.recoverPassword(self);
        });
    }

    onNifChange(self) {
        $(document).on('change', '#isAutonomous', function (e) {
            if ($(e.currentTarget).is(':checked')) {
                $('#registerNif').removeAttr('disabled');
                $('.nif-helper-message').toggle();
            } else {
                $('#registerNif').attr('disabled', 'true');
                $('.nif-helper-message').toggle();
            }
        });
    }

    onAuthenticationFormInputsKeyPress(self) {
        $('#registerForm').find('input').on('keypress', function (e) {
            if (e.keyCode === 13) {
                $('#registerForm').find('input[required]').trigger('blur');
                let formEmailInput = $('#registerForm').find('input[type="email"]');
                if (!$(formEmailInput).hasClass('is-invalid')) {
                    $(formEmailInput).trigger('keyup');
                }
                self.register(self);
                return false;
            }
        });

        $('#lostPasswordForm').find('input').on('keypress', function (e) {
            if (e.keyCode === 13) {
                $('#lostPasswordForm').find('input[required]').trigger('blur');
                self.recoverPassword(self);
                return false;
            }
        });

        $('#authenticationContainer').find('input').on('keypress', function (e) {
            if (e.keyCode === 13) {
                self.logOn(self);
                return false;
            }
        });
    }

    onOpenModalRegister(self) {
        $('#modal-sign-up').on('show.bs.modal', function (e) {
            $(e.currentTarget).addClass('overflow-scroll');
        });
    }

    onCloseModalRegister(self) {
        $('#modal-sign-up').on('hidden.bs.modal', function (e) {
            $(e.currentTarget).removeClass('overflow-scroll');
            $('body').css('padding-right', '0');
        });
    }

    onShownAuthenticatioModal(self) {
        $(document).on('shown.bs.modal', '.modal-authentication-system', function (e) {
            $('body').addClass('modal-open');
        });
    }

    onShowAuthenticatioModal(self) {
        $(document).on('show.bs.modal', '.modal-authentication-system', function (e) {
            $('body').css('padding-right', '0');
            $('body').addClass('modal-open');
        });
    }



    logOn(self) {
        let username = $('#userName').val();
        let password = $('#logInPassword').val();
        let token = $('input[name=__RequestVerificationToken]').val();
        $.ajax({
            url: '/authenticate',
            method: 'POST',
            data: {
                __RequestVerificationToken: token,
                userNameOrEmail: username,
                password: password,
                returnUrl: window.location.href,
                rememberMe: false
            }
        }).done(function (result) {
            let html = $.parseHTML(result);
            let errorMessages = $(html).find('.validation-summary-errors');
            if (errorMessages.length > 0) {
                if ($('#authenticationContainer').find('.validation-summary-errors').length == 0) {
                    let errors = $(result).find('.validation-summary-errors').addClass('ml1');
                    $('#authenticationContainer').append(errors);
                    setTimeout(function () {
                        $(errors).remove();
                    }, 10000);
                }
            } else {
                window.localStorage.setItem("isAuthenticated", "true");
                window.location.reload();
            }
        }).fail(function (jqXHR, exception) {

        });
    }

    register(self) {
        let registerContainer = $('#registerContainer');
        if (this.inputValidator.isValidForm($("#registerForm"))) {
            let email = $('#registerEmail').val();
            let username = $('#registerUserName').val();
            let password = $('#registerPassword').val();
            let confirmPassword = $('#registerConfirmPassword').val();
            let checkRGPD = $('#checkRGPD').is(':checked');
            let recaptchaResponse = $('#g-recaptcha-response').val() ? $('#g-recaptcha-response').val() : null;

            let personalData = {
                UserName: username,
                Email: email,
                Name: $('#registerName').val(),
                LastName: $('#registerLastName').val(),
                BirthDate: $('#registerBirthDate').val() == "" ? new Date() : $('#registerBirthDate').val(),
                Phone: $('#registerTelephone').val(),
                HasNif: $('#isAutonomous').is(':checked') ? true : false,
                Nif: $('#registerNif').val(),
            }

            let token = $('input[name=__RequestVerificationToken]').val();

            if (recaptchaResponse == '' || !recaptchaResponse) {
                $('body').append('<div class="alert alert-dismissable alert-warning fade in alert-position-fixed alert-warning-register" style="bottom: 50px;">' + $('#noFilledCaptchaMessage').val() + '<button type="button" class="close" data-dismiss="alert"><span aria-hidden="true">×</span></button></div>');
                return;
            }

            $.ajax({
                url: '/register',
                method: 'POST',
                data: {
                    __RequestVerificationToken: token,
                    userName: username,
                    email: email,
                    password: password,
                    confirmPassword: confirmPassword,
                    checkRGPD: checkRGPD,
                    recaptchaResponse: recaptchaResponse
                }
            }).done(function (result) {

                try {
                    grecaptcha.reset();
                } catch {
                    console.log("grecaptcha is not enabled");
                }

                let html = $.parseHTML(result);
                let errorMessages = $(html).find('.validation-summary-errors');
                let alertErrorMessages = $(html).find('.alert-danger');
                let hasFoundSomeError = false;

                if (alertErrorMessages.length > 0 && alertErrorMessages.text().length > 0) {
                    if ($(registerContainer).find('.alert-danger').length == 0) {
                        let errors = '<div class="alert alert-danger alert-registration-error">' + $(alertErrorMessages).text() + '</div>';
                        $(registerContainer).append(errors);
                        setTimeout(function () {
                            $(registerContainer).find('.alert-registration-error').remove();
                        }, 10000);
                    }
                    hasFoundSomeError = true;
                }

                if (errorMessages.length > 0 && errorMessages.text().length > 0) {
                    if ($(registerContainer).find('.validation-summary-errors').length == 0) {
                        let errors = '<div class="alert alert-danger alert-registration-error">' + $(errorMessages).text() + '</div>';
                        $(registerContainer).append(errors);
                        setTimeout(function () {
                            $(registerContainer).find('.alert-registration-error').remove();
                        }, 10000);
                    }
                    hasFoundSomeError = true;
                }

                if (hasFoundSomeError){
                    return;
                }
                
                let isEnabledShopping = $('#isEnabledShopping').val()?.toLowerCase() === 'true';
                if (isEnabledShopping) {
                    window.localStorage.setItem("personalData", JSON.stringify(personalData));
                    window.localStorage.setItem("isRegistered", "ecommerce");
                    //window.location.search += '?isRegisteredEcommerceUser=true';
                } else {
                    window.localStorage.setItem("isRegistered", "common");
                    //window.location.search += '?isRegisteredUser=true';
                }

                window.location.reload();
                
            }).fail(function (jqXHR, exception) {
                grecaptcha.reset();
            });

        } else {
            let alert = $('.alert-danger').clone().removeAttr('hidden').text($('#invalidFormMessage').val());
            if ($(registerContainer).find('.alert-danger').length == 0) {
                $(registerContainer).append(alert);
                setTimeout(function () {
                    $(alert).remove();
                }, 5000);
            }
        }

    }

    setEcommerceUserRole(self, username) {
        let token = $('input[name=__RequestVerificationToken]').val();
        return $.ajax({
            url: '/set-ecommerce-user-role',
            method: 'POST',
            data: {
                __RequestVerificationToken: token,
                username: username
            }
        }).done(function (result) {

        }).fail(function (jqXHR, exception) {

        });
    }


    recoverPassword(self) {
        if (this.inputValidator.isValidForm($("#lostPasswordForm"))) {
            let token = $('input[name=__RequestVerificationToken]').val();
            let username = $('#lostPasswordUserName').val();
            let lostPasswordContainer = $('#lostPasswordContainer');
            $.ajax({
                url: '/request-lost-password',
                method: 'POST',
                data: {
                    __RequestVerificationToken: token,
                    username: username
                }
            }).done(function (result) {
                let alert = $('.alert-success').clone().removeAttr('hidden').text($('#recoverPasswordRequestMessage').val());
                if ($(lostPasswordContainer).find('.alert-success').length == 0) {
                    $(lostPasswordContainer).append(alert);
                    setTimeout(function () {
                        $(alert).remove();
                    }, 5000);
                }
            }).fail(function (jqXHR, exception) {
                let alert = $('.alert-success').clone().text($('#recoverPasswordRequestFailedMessage').val());
                if ($(lostPasswordContainer).find('.alert-danger').length == 0) {
                    $(lostPasswordContainer).append(alert);
                    setTimeout(function () {
                        $(alert).remove();
                    }, 5000);
                }
            });
        } else {
            let alert = $('.alert-danger').clone().removeAttr('hidden').text($('#invalidFormMessage').val());
            if ($(lostPasswordContainer).find('.alert-danger').length == 0) {
                $(lostPasswordContainer).append(alert);
                setTimeout(function () {
                    $(alert).remove();
                }, 5000);
            }
        }
    }


    verifyAndGetRegistredUserId(self, username) {
        let token = $('input[name=__RequestVerificationToken]').val();
        return $.ajax({
            url: '/verify-get-registred-user',
            method: 'POST',
            data: {
                __RequestVerificationToken: token,
                username: username
            }
        }).done(function (result) {

        }).fail(function (jqXHR, exception) {

        });
    }

    registerPersonalData(self, personalData) {
        let token = $('input[name=__RequestVerificationToken]').val();
        return $.ajax({
            url: self.baseUrl + 'save-user-data-from-cart',
            method: 'POST',
            data: {
                __RequestVerificationToken: token,
                request: JSON.stringify(personalData)
            }
        }).done(function (result) {

        }).fail(function (jqXHR, exception) {

        });
    }

    getUrlParameterValue(name) {

        var results = new RegExp('[\?&]' + name + '=([^&#]*)').exec(window.location.href);
        if (results == null) {
            return null;
        }
        return decodeURI(results[1]) || 0;
    }

}

$(document).ready(() => { new Authentication(); });